<template>
  <div id="app">
    <!--<p>{{ $t('message') }}</p>-->
    <router-view />
  </div>
</template>

<script>
  import navbar from "@/components/navbar";
  import footer from "@/components/footer";

  export default{
    name:'app',
    components: {
      'navbar': navbar,
      'Footer': footer,
    },
  }
</script>

<style lang="scss">
::-moz-selection { /* Code for Firefox */
  color: white;
  background-color: var(--canli-primary) !important;
}

::selection {
  color: white;
  background-color: var(--canli-primary) !important;
}
</style>
