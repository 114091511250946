<script>
import {ArrowDownIconIcon} from "vue-feather-icons";

/**
 * Navbar component
 */
export default {
  data() {
    return {
      isCondensed: false,
      loginUrl: process.env.VUE_APP_ROOT_URL + 'login',
      signupUrl: process.env.VUE_APP_ROOT_URL + 'signup',
      currentLanguage: {
        text: 'TR',
        countryCode: 'TR',
        icon: 'uil uil-icon1',
        imgPath: 'images\\748103_flag_turkey_icon.png'
      },
      languageOptions: {
        TR: {name:'TR', text: 'Türkçe', countryCode: 'tr', icon: 'uil uil-icon1', imgPath: 'images\\748103_flag_turkey_icon.png'},
        EN: {
          name:'EN',
          text: 'English',
          countryCode: 'en',
          icon: 'uil uil-icon2',
          imgPath: 'images\\748024_flag_kingdom_united_icon.png'
        },
        MK: {
          name:'MK',
          text: 'македонски',
          countryCode: 'mk',
          icon: 'uil uil-icon2',
          imgPath: 'images\\macedoniaFlag3.png',
          width:'10px'
        },
        /*DE: {text: 'Deutsch',countryCode:'DE', icon: 'uil uil-anchor', imgPath:'images\\748067_flag_germany_icon.png'},*/
      },
      dropdownOpen: false,
      isSubMenuVisible:true
    };
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
    isIcons: {
      type: Boolean,
    },
  },

  updated: () => {

    var grandparent = document.querySelectorAll(".navigation-menu li");

    for (var i = 0; i < grandparent.length; i++) {
      if (grandparent[i].classList.value.sub("active")) {
        if (grandparent[i].classList.value.startsWith("has-submenu")) {
          grandparent[i].classList.value = "has-submenu"
        } else {
          grandparent[i].classList.value = ""
        }
      }
    }

    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      for (var j = 0; j < links.length; j++) {
        if (links[j].classList.contains("active")) {
          links[j].classList.remove("active")

        }
      }
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;
      if (parent) {
        parent.classList.add("active");
      }
    }
  },
  mounted: () => {
    window.onscroll = function () {
      onwindowScroll();
    };

    // activate section header

    var grandparent = document.querySelectorAll(".navigation-menu li");
    var test = document.querySelectorAll(".navigation-menu li a");

    for (var i = 0; i < test.length; i++) {

      if (window.location.pathname === test[i].getAttribute("href")) {
        if (i < 3) {
          grandparent[i].classList.add("active")
        } else {
          grandparent[i - 1].classList.add("active")
        }
        break;
      }
    }

    function onwindowScroll() {
      if (
          document.body.scrollTop > 50 ||
          document.documentElement.scrollTop > 50
      ) {
        document.getElementById("topnav").classList.add("nav-sticky");
      } else {
        document.getElementById("topnav").classList.remove("nav-sticky");
      }

      if (
          document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 100
      ) {
        document.getElementById("back-to-top").style.display = "inline";
      } else {
        document.getElementById("back-to-top").style.display = "none";
      }
    }

    // function subMenuHideFunc(){
    //   console.log('OHYEAAHHH!!!')
    // }

    /*var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /!**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       *!/

      if (parent) {
        parent.classList.add("active");
      }
      /!*if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add("active");
            }
          }
        }
      }*!/
    }*/
  },
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
      this.subMenuHideFunc()
    },
    // toggleMenu() {
    //   console.log('WORKED')
    //   console.log(this.isCondensed)
    //   this.isCondensed = false//!this.isCondensed;
    //   if (this.isCondensed) {
    //     document.getElementById("navigation").style.display = "block";
    //   } else document.getElementById("navigation").style.display = "none";
    //   console.log(this.isCondensed)
    // },

    subMenuHideFunc(){
      this.isSubMenuVisible=false;
    },
    subMenuShowFunc(){
      this.isSubMenuVisible=true;
    },

    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;

      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    },

    /**
     * Update language selection on dropdown
     */
    selectLanguage(selection) {
      this.currentLanguage.text = selection.text
      this.currentLanguage.countryCode = selection.countryCode
      this.currentLanguage.icon = selection.icon
      this.currentLanguage.imgPath = selection.imgPath

      this.$i18n.locale = selection.countryCode
      console.log("The language is now " + selection.text)
    },

    slideToSection(sectionId) {

      if (window.location.pathname !== "/") return

      setTimeout(function () {

        var targetElement = document.getElementById(sectionId)

        if (targetElement == null) return;

        var headerOffset = 79;
        var elementPosition = targetElement.getBoundingClientRect().top;
        var offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });

      }, 250);
    },

  },
};
</script>

<template>
  <div>
    <!-- Navbar STart -->
    <header
        id="topnav"
        class="defaultscroll sticky"
        :class="{ 'bg-white': isWhiteNavbar === true }"
    >
      <div class="container" style="padding-top: 5px">
        <!-- Logo container-->

        <div id="logodiv" style="justify-content: center">
          <router-link class="logo" to="/" v-if="navLight !== true">
            <img src="../../public/images/beyaz-arkaplan-decoupe.png" height="55" alt="beyaz-arkaplan-decoupe"/>
          </router-link>
          <router-link class="logo" to="/" v-else>
            <img src="../../public/images/beyaz-arkaplan-decoupe.png" class="l-dark" height="55" alt="beyaz-arkaplan-decoupe"/>
            <img
                src="../../public/images/koyu-arkaplan-decoupe.png"
                class="l-light"
                height="55"
                alt="koyu-arkaplan-decoupe"
            />
          </router-link>
        </div>
        <div class="menu-extras">
          <div class="menu-item">
            <!-- Mobile menu toggle-->
            <a
                class="navbar-toggle"
                @click="toggleMenu()"
                :class="{ open: isCondensed === true }"
            >
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </div>
        </div>
        <div class="buy-button" id="signIn_btn" v-if="isIcons !== true">

          <a
              :href="loginUrl"
              target="_blank"
              class="btn"
              :class="{
              'btn-light': navLight === true,
              'btn-primary': navLight !== true,
            }"
          >{{ $t('navbar.login') }}</a
          >

        </div>

        <div class="buy-button mr-2" id="signUp_btn" v-if="isIcons !== true">

          <a
              :href="signupUrl"
              target="_blank"
              class="btn"
              :class="{
              'btn-light': navLight === true,
              'btn-primary': navLight !== true,
            }"
          >{{ $t('navbar.signup') }}</a
          >

        </div>


        <div class="buy-button">
          <b-dropdown menu-class="btn-white btn-sm" toggle-class="btn-light pl-1 pr-1 pt-0 pb-0"
                      id="dropdown-language_btn" class="m-2">
            <div style="max-width: 50px!important; padding: 0 !important; margin: 0;" slot="button-content">
              <img class="pb-1" :src="currentLanguage.imgPath"/> <i class="uil uil-angle-down" id="dropdown_icon"></i>
              <!--{{currentLanguage.countryCode}}-->
            </div>
            <b-dropdown-item class="p-0" v-for="languageOption in languageOptions" :key="languageOption.name"
                             @click="selectLanguage(languageOption)">
              <!--              <i  :class="languageOption.icon"></i>{{languageOption.text}}-->
              <img :src="languageOption.imgPath"> {{ languageOption.text }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <ul class="buy-button list-inline mb-0" v-if="isIcons === true">
          <li class="list-inline-item mb-0 developer-icon">
            <b-dropdown
                right
                variant="link"
                toggle-class="text-decoration-none p-0 pr-2"
                menu-class="dd-menu dropdown-menu-right bg-white shadow rounded border-0 mt-3 py-0"
            >
              <template #button-content>
                <i class="mdi mdi-magnify h4 text-muted"></i>
              </template>
              <div style="width: 300px">
                <form>
                  <input
                      type="text"
                      id="text"
                      name="name"
                      class="form-control border bg-white"
                      placeholder="Search..."
                  />
                </form>
              </div>
            </b-dropdown>
          </li>
          <li class="list-inline-item mb-0 pr-2">
            <a href="#" class="btn btn-icon btn-soft-primary"
            ><i class="mdi mdi-github mdi-18px icons"></i
            ></a>
          </li>
          <li class="list-inline-item mb-0 pr-2">
            <a href="#" class="btn btn-icon btn-soft-primary"
            ><i class="mdi mdi-stack-overflow mdi-18px icons"></i
            ></a>
          </li>
          <li class="list-inline-item mb-0">
            <a
                href="javascript:void(0)"
                class="btn btn-icon btn-soft-primary"
                data-toggle="modal"
                data-target="#productview"
            ><i class="mdi mdi-account-outline mdi-18px icons"></i
            ></a>
          </li>
        </ul>
        <!--end login button-->
        <!--end login button-->
        <!-- End Logo container-->


        <div id="navigation">
          <!--Navigation Menu-->
          <ul
              class="navigation-menu"
              :class="{ 'nav-light': navLight === true }"
          >

            <li @click="toggleMenu()">
              <router-link to="/" class="side-nav-link-ref">{{ $t('navbar.Home') }}</router-link>
            </li>

            <li class="has-submenu" @mouseenter="subMenuShowFunc()">
              <router-link id="cozumler_notMobileOnly_button" to="/Solutions" @click="onMenuClick"
                           class="side-nav-link-ref">{{ $t('navbar.Solutions.title') }}
              </router-link>
              <a id="cozumler_mobileOnly_button" href="javascript:void(0)"
                 @click="onMenuClick">{{ $t('navbar.Solutions.title') }}</a>
              <span class="menu-arrow"></span>

              <ul v-if="$route.path.startsWith('/Solutions') && isSubMenuVisible" class="submenu"
                  ><!--:class="[isSubMenuVisible ? '' : ' submenu-hide submenu-text-hide']"-->
                <li @click="toggleMenu()"><!--@mouseup="subMenuHideFunc()"-->
                  <router-link to="/Solutions#insan" v-b-toggle:accordion-1 v-scroll-to="{el:'#insan', offset: -100,  duration: 100}"
                               class="side-nav-link-ref">
                    <i class="submenu-iconsize uil uil-users-alt float-left pr-3 "> </i>
                    <p class="submenu-text">{{ $t('navbar.Solutions.subTitle.people') }}</p>
                  </router-link>
                </li>
                <li @click="toggleMenu()"><!--@mouseup="subMenuHideFunc()"-->
                  <router-link to="/Solutions#operasyon" v-b-toggle:accordion2-1 v-scroll-to="{el:'#operasyon', offset: -250,  duration: 100}"
                               class="side-nav-link-ref" >
                    <i class="submenu-iconsize uil uil-process float-left pr-3"> </i>
                    <p class="submenu-text">{{ $t('navbar.Solutions.subTitle.operation') }}</p>
                  </router-link>
                </li>

                <li @click="toggleMenu()"><!--@mouseup="subMenuHideFunc()"-->
                  <router-link to="/Solutions#pazarlama" v-b-toggle:accordion2-1 v-scroll-to="{el:'#pazarlama', offset: -250,  duration: 100}"
                               class="side-nav-link-ref" >
                               <i class="submenu-iconsize material-icons-outlined float-left pr-3 pt-1" >payments</i>
                    <p class="submenu-text">{{ $t('navbar.Solutions.subTitle.marketing') }}</p>
                  </router-link>
                </li>
                <li @click="toggleMenu()">
                  <router-link to="/Solutions#tesis" v-b-toggle:accordion3-1 v-scroll-to="{el:'#tesis', offset: -250,  duration: 100}"
                               class="side-nav-link-ref">
                    <!-- <i class="submenu-iconsize uil uil-bill float-left pr-3"> </i>  -->
                    <i class="submenu-iconsize material-icons-outlined float-left pr-3 pt-1" >domain</i>
                    <p class="submenu-text">{{ $t('navbar.Solutions.subTitle.facilities') }}</p>
                  </router-link>
                </li>
                <li @click="toggleMenu()">
                  <router-link to="/Solutions#kiraci" v-b-toggle:tenantaccordion-1 v-scroll-to="{el:'#kiraci', offset: -250,  duration: 100}"
                               class="side-nav-link-ref">
                    <i class="submenu-iconsize material-icons-outlined float-left pr-3 pt-1" >storefront</i>
                    <p class="submenu-text">{{ $t('navbar.Solutions.subTitle.tenant') }}</p>
                  </router-link>
                </li>
                <li @click="toggleMenu()">
                  <router-link to="/Solutions#finansal" v-b-toggle:accordion3-1 v-scroll-to="{el:'#finansal', offset: -250,  duration: 100}"
                               class="side-nav-link-ref">
                    <i class="submenu-iconsize uil uil-bill float-left pr-3"> </i>
                    <p class="submenu-text">{{ $t('navbar.Solutions.subTitle.financial') }}</p>
                  </router-link>
                </li>
                
                <li @click="toggleMenu()">
                  <router-link to="/Solutions#raporlama" v-b-toggle:accordion4-1 v-scroll-to="{el:'#raporlama', offset: -250,  duration: 100}"
                               class="side-nav-link-ref">
                    <i class="submenu-iconsize uil uil-file-alt float-left pr-3"> </i>
                    <p class="submenu-text">{{ $t('navbar.Solutions.subTitle.reporting') }}</p>
                  </router-link>
                </li>
              </ul>

              <ul v-else-if="isSubMenuVisible" class="submenu">
                <li @click="toggleMenu()">
                  <router-link to="/Solutions#insan" class="side-nav-link-ref">
                    <i class="submenu-iconsize uil uil-users-alt float-left pr-3"> </i>
                    <p class="submenu-text">{{ $t('navbar.Solutions.subTitle.people') }}</p>
                  </router-link>
                </li>
                <li @click="toggleMenu()">
                  <router-link to="/Solutions#operasyon"   class="side-nav-link-ref">
                    <i class="submenu-iconsize uil uil-process float-left pr-3"> </i>
                    <p class="submenu-text">{{ $t('navbar.Solutions.subTitle.operation') }}</p>
                  </router-link>
                </li>
                <li @click="toggleMenu()">
                  <router-link to="/Solutions#pazarlama"   class="side-nav-link-ref">
                    <i class="submenu-iconsize material-icons-outlined float-left pr-3 pt-1" style="color: #3c4858;" >payments</i>
                    <p class="submenu-text">{{ $t('navbar.Solutions.subTitle.marketing') }}</p>
                  </router-link>
                </li>
                <li @click="toggleMenu()">
                  <router-link to="/Solutions#tesis"   class="side-nav-link-ref">
                    <i class="submenu-iconsize material-icons-outlined float-left pr-3 pt-1" style="color: #3c4858;"  >domain</i>
                    <p class="submenu-text">{{ $t('navbar.Solutions.subTitle.facilities') }}</p>
                  </router-link>
                </li>
                <li @click="toggleMenu()">
                  <router-link to="/Solutions#kiraci" class="side-nav-link-ref">
                    <i class="submenu-iconsize material-icons-outlined float-left pr-3 pt-1" >storefront</i>
                    <p class="submenu-text">{{ $t('navbar.Solutions.subTitle.tenant') }}</p>
                  </router-link>
                </li>
                <li @click="toggleMenu()">
                  <router-link to="/Solutions#finansal" class="side-nav-link-ref">
                    <i class="submenu-iconsize uil uil-bill float-left pr-3"> </i>
                    <p class="submenu-text">{{ $t('navbar.Solutions.subTitle.financial') }}</p>
                  </router-link>
                </li>
                
                <li @click="toggleMenu()">
                  <router-link to="/Solutions#raporlama" class="side-nav-link-ref">
                    <i class="submenu-iconsize uil uil-file-alt float-left pr-3"> </i>
                    <p class="submenu-text">{{ $t('navbar.Solutions.subTitle.reporting') }}</p>
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="has-submenu" @mouseenter="subMenuShowFunc()">
              <router-link id="urunler_notMobileOnly_button" to="/Products_Mall" @click="onMenuClick"
                           class="side-nav-link-ref">{{ $t('navbar.Products.title') }}
              </router-link>
              <a id="urunler_mobileOnly_button" href="javascript:void(0)"
                 @click="onMenuClick">{{ $t('navbar.Products.title') }}</a>
              <span class="menu-arrow"></span>


              <ul v-if="$route.path.startsWith('/Products_Mall') && isSubMenuVisible" class="submenu" 
              ><!--:class="[isSubMenuVisible ? '' : ' submenu-hide submenu-text-hide']"-->
                <li @click="toggleMenu()" ><!--@mouseup="subMenuHideFunc()"-->
                  <router-link to="/Products_Mall" v-b-toggle:accordion-1 v-scroll-to="{el:'#mall', offset: -100,  duration: 100}"
                               class="side-nav-link-ref" >
                    <div class="" >
                      <span class="material-icons-outlined submenu-iconsize float-left pr-3"> local_mall </span>
                      <p class="submenu-text products" style="text-transform: none !important;" >{{ $t('navbar.Products.subTitle.mall') }}</p>
                    </div>
                  </router-link>
                </li>
                <li @click="toggleMenu()"><!--@mouseup="subMenuHideFunc()"-->
                  <router-link to="/Products_Retail" v-b-toggle:accordion2-1 v-scroll-to="{el:'#retail', offset: -250,  duration: 100}"
                               class="side-nav-link-ref" >
                    <div class="">
                      <span class="material-icons-outlined submenu-iconsize float-left pr-3"> shopping_cart </span>
                      <p class="submenu-text products" style="text-transform: none !important;">{{ $t('navbar.Products.subTitle.retail') }}</p>
                    </div>
                  </router-link>
                </li>
                <li @click="toggleMenu()">
                  <router-link to="/Products_Office" v-b-toggle:tenantaccordion-1 v-scroll-to="{el:'#office', offset: -250,  duration: 100}"
                               class="side-nav-link-ref">
                    <div class="">
                      <span class="material-icons-outlined submenu-iconsize float-left pr-3"> print </span>
                      <p class="submenu-text products" style="text-transform: none !important;">{{ $t('navbar.Products.subTitle.office') }}</p>
                    </div>
                  </router-link>
                </li>
                <li @click="toggleMenu()">
                  <router-link to="/Products_Industrial" v-b-toggle:tenantaccordion-1 v-scroll-to="{el:'#office', offset: -250,  duration: 100}"
                               class="side-nav-link-ref">
                    <div class="">
                      <span class="material-icons-outlined submenu-iconsize float-left pr-3"> factory </span>
                      <p class="submenu-text products" style="text-transform: none !important;">{{ $t('navbar.Products.subTitle.industrial') }}</p>
                    </div>
                  </router-link>
                </li>

              </ul>

              <ul v-else-if="isSubMenuVisible" class="submenu">
                <li @click="toggleMenu()">
                  <router-link to="/Products_Mall" class="side-nav-link-ref">
                    <div style="display: flex ; flex-wrap: wrap;">
                      <span class="material-icons-outlined submenu-iconsize float-left pr-3" style="padding-top:4px"> local_mall </span>
                      <p class="submenu-text" style="text-transform: none !important;" >{{ $t('navbar.Products.subTitle.mall') }}</p>
                      <span><font-awesome-icon class="pl-3" style="font-size: 1.05rem; padding-top:5px"  icon="fa-solid fa-code-branch" /></span>
                    </div>
                  </router-link>
                </li>
                <li @click="toggleMenu()">
                  <router-link to="/Products_Retail"   class="side-nav-link-ref">
                    <div style="display: flex ; flex-wrap: wrap;">
                      <span class="material-icons-outlined submenu-iconsize float-left pr-3" style="padding-top:4px"> shopping_cart </span>
                      <p class="submenu-text" style="text-transform: none !important;" >{{ $t('navbar.Products.subTitle.retail') }}</p>
                      <span><font-awesome-icon class="pl-3" style="font-size: 1.05rem; padding-top:5px"  icon="fa-solid fa-brain" /></span>

                    </div>
                  </router-link>
                </li>
                <li @click="toggleMenu()">
                  <router-link to="/Products_Office" class="side-nav-link-ref">
                    <div style="display: flex ; flex-wrap: wrap;">
                      <span class="material-icons-outlined submenu-iconsize float-left pr-3" style="padding-top:4px"> print </span>
                      <p class="submenu-text" style="text-transform: none !important;">{{ $t('navbar.Products.subTitle.office') }}</p>
                      <span><font-awesome-icon class="pl-3" style="font-size: 1.05rem; padding-top:5px"  icon="fa-solid fa-brain" /></span>

                    </div>
                  </router-link>
                </li>
                <li @click="toggleMenu()">
                  <router-link to="/Products_Industrial" class="side-nav-link-ref">
                    <div style="display: flex ; flex-wrap: wrap;">
                      <span class="material-icons-outlined submenu-iconsize float-left pr-3" style="padding-top:4px"> factory </span>
                      <p class="submenu-text" style="text-transform: none !important;">{{ $t('navbar.Products.subTitle.industrial') }}</p>
                      <span><font-awesome-icon class="pl-3" style="font-size: 1.05rem; padding-top:5px"  icon="fa-solid fa-brain" /></span>

                    </div>
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="has-submenu" @mouseenter="subMenuShowFunc()">
              <router-link id="sektorler_notMobileOnly_button" to="/Sectors_Avm" @click="onMenuClick"
                           class="side-nav-link-ref">{{ $t('navbar.Sectors.title') }}
              </router-link>
              <a id="sektorler_mobileOnly_button" href="javascript:void(0)"
                 @click="onMenuClick">{{ $t('navbar.Sectors.title') }}</a>
              <span class="menu-arrow"></span>

              <ul v-if="$route.path.startsWith('/Sectors_Avm') && isSubMenuVisible" class="submenu"
              ><!--:class="[isSubMenuVisible ? '' : ' submenu-hide submenu-text-hide']"-->
                <li @click="toggleMenu()"><!--@mouseup="subMenuHideFunc()"-->
                  <router-link to="/Sectors_Avm" v-b-toggle:accordion-1 v-scroll-to="{el:'#avm', offset: -100,  duration: 100}"
                               class="side-nav-link-ref">
                    <div class="">
                      <span class="material-icons-outlined submenu-iconsize float-left pr-3"> local_mall </span>
                      <p class="submenu-text">{{ $t('navbar.Sectors.subTitle.avm') }}</p>
                    </div>

                  </router-link>
                </li>
                <li @click="toggleMenu()"><!--@mouseup="subMenuHideFunc()"-->
                  <router-link to="/Sectors_Retail" v-b-toggle:accordion-1 v-scroll-to="{el:'#retail', offset: -100,  duration: 100}"
                               class="side-nav-link-ref">
                    <div class="">
                      <span class="material-icons-outlined submenu-iconsize float-left pr-3"> shopping_cart </span>
                      <p class="submenu-text">{{ $t('navbar.Sectors.subTitle.retail') }}</p>
                    </div>

                  </router-link>
                </li>
                <li @click="toggleMenu()"><!--@mouseup="subMenuHideFunc()"-->
                  <router-link to="/Sectors_Office" v-b-toggle:accordion-1 v-scroll-to="{el:'#office', offset: -100,  duration: 100}"
                               class="side-nav-link-ref">
                    <div class="">
                      <span class="material-icons-outlined submenu-iconsize float-left pr-3"> print </span>
                      <p class="submenu-text">{{ $t('navbar.Sectors.subTitle.office') }}</p>
                    </div>

                  </router-link>
                </li>
                <li @click="toggleMenu()"><!--@mouseup="subMenuHideFunc()"-->
                  <router-link to="/Sectors_Industrial" v-b-toggle:accordion-1 v-scroll-to="{el:'#industrial', offset: -100,  duration: 100}"
                               class="side-nav-link-ref">
                    <div class="">
                      <span class="material-icons-outlined submenu-iconsize float-left pr-3"> factory </span>
                      <p class="submenu-text">{{ $t('navbar.Sectors.subTitle.industrial') }}</p>
                    </div>

                  </router-link>
                </li>

              </ul>

              <ul v-else-if="isSubMenuVisible" class="submenu">
                <li @click="toggleMenu()">
                  <router-link to="/Sectors_Avm" class="side-nav-link-ref">

                    <div class="">
                      <span class="material-icons-outlined submenu-iconsize float-left pr-3"> local_mall </span>
                      <p class="submenu-text">{{ $t('navbar.Sectors.subTitle.avm') }}</p>
                    </div>

                  </router-link>
                </li>
                <li @click="toggleMenu()">
                  <router-link to="/Sectors_Retail" class="side-nav-link-ref">

                    <div class="">
                      <span class="material-icons-outlined submenu-iconsize float-left pr-3"> shopping_cart </span>
                      <p class="submenu-text">{{ $t('navbar.Sectors.subTitle.retail') }}</p>
                    </div>

                  </router-link>
                </li>
                <li @click="toggleMenu()">
                  <router-link to="/Sectors_Office" class="side-nav-link-ref">

                    <div class="">
                      <span class="material-icons-outlined submenu-iconsize float-left pr-3"> print </span>
                      <p class="submenu-text">{{ $t('navbar.Sectors.subTitle.office') }}</p>
                    </div>

                  </router-link>
                </li>
                <li @click="toggleMenu()">
                  <router-link to="/Sectors_Industrial" class="side-nav-link-ref">

                    <div class="">
                      <span class="material-icons-outlined submenu-iconsize float-left pr-3"> factory </span>
                      <p class="submenu-text">{{ $t('navbar.Sectors.subTitle.industrial') }}</p>
                    </div>

                  </router-link>
                </li>

              </ul>
            </li>
            <!-- <li @click="toggleMenu()">
              <router-link to="/references" class="side-nav-link-ref">{{ $t('navbar.References') }}</router-link>
            </li> -->

            <li @click="toggleMenu()">
              <router-link to="/aboutUs" class="side-nav-link-ref">{{ $t('navbar.Corporate') }}</router-link>
            </li>

            <li @click="toggleMenu()">
              <router-link to="contact" class="side-nav-link-ref">{{ $t('navbar.Contact') }}</router-link>
            </li>

          </ul>
          <!--end navigation menu-->
          <div>

          </div>

          <div class="buy-menu-btn">
            <b-dropdown menu-class="btn-white btn-sm" toggle-class="btn-light pl-1 pr-1 pt-0 pb-0"
                        id="dropdown-language_menu-btn">
              <div style="max-width: 50px!important; padding: 0 !important; margin: 0;" slot="button-content">
                <img class="pb-1" :src="currentLanguage.imgPath"/> <i class="uil uil-angle-down"
                                                                      id="menu_dropdown_icon"></i>
                <!--                {{currentLanguage.countryCode}}-->
              </div>
              <b-dropdown-item class="p-0" v-for="languageOption in languageOptions" :key="languageOption.name"
                               @click="selectLanguage(languageOption)">
                <!--              <i  :class="languageOption.icon"></i>{{languageOption.text}}-->
                <img :src="languageOption.imgPath"> {{ languageOption.text }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <!--end login button-->
        </div>
        <!--end navigation-->
      </div>
      <!--end container-->
    </header>
    <!--end header-->
    <!-- Navbar End -->
  </div>
</template>

<style scoped>

.material-icons-outlined{
  font-size: 1em!important;
  margin: 0px;
}
.submenu-iconsize {
  font-size: 1.05rem !important;
}

.products{
  text-transform: none !important;
}
.submenu-text {
  margin-top: 0.2rem
}

.submenu-hide{
  visibility: hidden !important;

}
.submenu-text-hide {
  visibility: hidden !important;
}

#dropdown-language_btn > ul {
  min-width: 8rem
}

#dropdown-language_menu-btn > ul {
  min-width: 8rem
}

@media (min-width: 500px) {
  #dropdown-language_menu-btn {
    display: none;

  }
}

@media (min-width: 375px) {
  #dropdown-language_menu-btn {
    margin-left: 6%;

  }
}

@media (max-width: 375px) {
  #topnav .buy-button {
    display: inline-block;
  }

  #logodiv {
    position: absolute;
    left: 50%;
    transform: translateX(-40%);

  }

  /*#dropdown-language_btn {
    display: none;
  }*/

}

@media (max-width: 500px) {

  #logodiv {
    position: absolute;
    left: 38% !important;
  }

  #dropdown-language_btn {
    display: none;
  }
}

@media (max-width: 991px) {
  .menu-extras {
    float: left !important;
  }

  #logodiv {
    position: absolute;
    left: 45%;
  }

  #cozumler_notMobileOnly_button {
    display: none !important;
  }

  #cozumler_mobileOnly_button {
    display: inline-block !important;
    padding-right: 10px !important;
  }
  #urunler_notMobileOnly_button {
    display: none !important;
  }

  #urunler_mobileOnly_button {
    display: inline-block !important;
    padding-right: 10px !important;
  }
  #sektorler_notMobileOnly_button {
    display: none !important;
  }
  #sektorler_mobileOnly_button {
    display: inline-block !important;
    padding-right: 10px !important;
  }
  .menu-arrow {
    display: inline-block !important;
    position: unset !important;
    margin-bottom: 1.5px !important;
  }
}

@media (min-width: 992px) {
  #cozumler_mobileOnly_button {
    display: none !important;
  }
  #urunler_mobileOnly_button {
    display: none !important;
  }
  #sektorler_mobileOnly_button {
    display: none !important;
  }

  .submenu {
    min-width: 230px !important;
  }

}

.buy-button {
  padding-left: 0px !important;
  margin-left: 0px !important;
}

.buy-button > a {
  padding: 0.5em 0.5em;
  font-size: 60%;
}

#dropdown-language_btn > button[aria-expanded="true"] > div > #dropdown_icon:before {
  transform: rotate(180deg);
  transition: all 0.25s ease-out;
}

#dropdown-language_btn > button[aria-expanded="false"] > div > #dropdown_icon:before {
  transform: rotate(0deg);
  transition: all 0.25s ease-out;
}

</style>
