import Vue from 'vue'
import i18n from './plugins/i18n_util_wrapper.js'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import "../public/css/materialdesignicons.min.css";
import 'swiper/swiper-bundle.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../public/css/colors/gr-default.css'
import '../public/css/gr.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBrain, faCodeBranch, faBullhorn } from '@fortawesome/free-solid-svg-icons'
import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import { BootstrapVue } from 'bootstrap-vue'

library.add(faBrain, faCodeBranch, faBuilding, faBullhorn)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, { duration: 100, easing: "ease" })
Vue.use(BootstrapVue)

new Vue({
  router,
  i18n,
  data: {
    showPopup: false, 
    showPopup1: false, 
  },
  render: h => h(App)
}).$mount('#app')
