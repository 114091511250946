<script>
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  MailIcon,
} from "vue-feather-icons";
export default {
  data() {
    return {};
  },
  components: {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MailIcon,
  },
};
</script>

<template>
  <div>
    <!-- Footer Start -->
    <footer class="footer">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-3 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <a href="#" class="logo-footer">
              <img src="../../public/images/siyah-arkaplan-decoupe.png" height="55" alt="Siyah arkaplan decoupe" />
            </a>
            <p class="mt-4">
              {{ $t('footer.motto') }}
            </p>
            <ul class="list-unstyled social mb-0 mt-3">
              <li class="list-inline-item" style="border-radius: 45%;">
                <a href="https://www.facebook.com/eppso.proptech?mibextid=ZbWKwL" target="_blank" >
                  <img src="../../public/images/Facebook.svg" alt="Facebook logo" height="30" >
                </a>
              </li>
              <li class="list-inline-item ml-2" style="border-radius: 45%;">
                <a href="https://www.instagram.com/eppso.proptech" target="_blank">
                  <img src="../../public/images/Instagram.svg" alt="Instagram logo" height="30">
                </a>
              </li>
              <li class="list-inline-item ml-2" style="border-radius: 45%;">
                <a href="https://x.com/EppsoPropTech?t=8_GeDPVNEyoPvFa9ORUFuw&s=09" target="_blank">
                  <img src="../../public/images/X.svg" alt="X logo" height="30">
                </a>
              </li>
              <li class="list-inline-item ml-2" style="border-radius: 45%;">
                <a href="https://www.linkedin.com/company/eppso-property-technologies/"  target="_blank">
                  <img src="../../public/images/Linkedin.svg" alt="Linkedin Logo" height="30">
                </a>
              </li>
            </ul>
            <!--end icon-->
            <!--TODO: eger iso belgeleri icin sayfa eklenecekse buraya href eklenebilir.-->
            <img src="../../public/images/9001.png" height="75" alt="ISO 9001" style="padding-right: 1rem;" />
            <img src="../../public/images/27001.png" height="75" alt="ISO 27001" />
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 mt-md-0 pt-2 pt-sm-0" >
            <h5 class="text-light footer-head">{{ $t('footer.siteMap.title') }}</h5>
            <ul id="siteHaritasiList" class="list-unstyled footer-list mt-4">
              <li>
                <router-link to="/Solutions" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.siteMap.Solutions') }}
                </router-link>
              </li>
              <li>
                <router-link to="/Products_Mall" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.siteMap.Products') }}
                </router-link>
              </li>
              <li>
                <router-link to="/Sectors_Avm" v-scroll-to="'#sektorler'" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.siteMap.Sectors') }}
                </router-link>
              </li>
              <li>
                <router-link to="/aboutUs" v-scroll-to="'#topnav'" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.siteMap.Corporate') }}
                </router-link>
              </li>
              <li>
                <router-link to="/contact" v-scroll-to="'#topnav'" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.siteMap.Contact') }}
                </router-link
                >
              </li>
            </ul>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 mt-md-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">{{ $t('footer.Solutions.title') }}</h5>


            <ul v-if="$route.path.startsWith('/Solutions')" class="cozumlerList list-unstyled footer-list mt-4" style="text-align: left"> <!-- id="cozumlerList" >-->
              <li>
                <router-link to="/Solutions#insan" v-scroll-to="{el:'#insan', offset: -100,  duration: 500}" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.Solutions.people') }}
                </router-link>
              </li>
              <li>
                <router-link to="/Solutions#operasyon" v-scroll-to="{el:'#operasyon', offset: -100,  duration: 500}" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.Solutions.operation') }}
                </router-link>
              </li>
              <li>
                <router-link to="/Solutions#pazarlama" v-scroll-to="{el:'#operasyon', offset: -100,  duration: 500}" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.Solutions.marketing') }}
                </router-link>
              </li>
              <li>
                <router-link to="/Solutions#kiraci" v-scroll-to="{el:'#kiraci', offset: -100,  duration: 500}" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.Solutions.tenant') }}
                </router-link>
              </li>
              <li>
                <router-link to="/Solutions#finansal" v-scroll-to="{el:'#finansal', offset: -100,  duration: 500}" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.Solutions.financial') }}
                </router-link>
              </li>
              <li>
                <router-link to="/Solutions#tesis" v-scroll-to="{el:'#operasyon', offset: -100,  duration: 500}" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.Solutions.facilities') }}
                </router-link>
              </li>
              <li>
                <router-link to="/Solutions#raporlama" v-scroll-to="{el:'#raporlama', offset: -100,  duration: 500}" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.Solutions.reporting') }}
                </router-link
                >
              </li>
            </ul>

            <ul v-else class="cozumlerList list-unstyled footer-list mt-4" style="text-align: left"> <!-- id="cozumlerList" >-->
              <li>
                <router-link to="/Solutions#insan" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.Solutions.people') }}
                </router-link>
              </li>
              <li>
                <router-link to="/Solutions#operasyon" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.Solutions.operation') }}
                </router-link>
              </li>
              <li>
                <router-link to="/Solutions#pazarlama" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.Solutions.marketing') }}
                </router-link>
              </li>
              <li>
                <router-link to="/Solutions#kiraci" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.Solutions.tenant') }}
                </router-link>
              </li>
              <li>
                <router-link to="/Solutions#finansal" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.Solutions.financial') }}
                </router-link>
              </li>
              <li>
                <router-link to="/Solutions#tesis" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.Solutions.facilities') }}
                </router-link>
              </li>
              <li>
                <router-link to="/Solutions#raporlama" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.Solutions.reporting') }}
                </router-link
                >
              </li>
            </ul>
          </div>
          <!--end col-->


          <div class="col-lg-3 col-md-6 col-12 mt-4 mt-md-0 pt-2 pt-sm-0" >
            <h5 class="text-light footer-head">{{ $t('footer.ilkeler.title') }}</h5>
            <ul id="ilkelerList" class="list-unstyled footer-list mt-4" style="text-align: left">
              <li>
                <router-link to="/aydinlatma_metni" v-scroll-to="'#topnav'" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.ilkeler.aydinlatmaMetni') }}
                </router-link>
              </li>
              <li>
                <router-link to="/uyelik_hizmet_sozlesmesi" v-scroll-to="'#topnav'" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.ilkeler.uyelikHizmetSozlesmesi') }}
                </router-link>
              </li>
              <li>
                <router-link to="/cerez_politikasi" v-scroll-to="'#topnav'" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.ilkeler.cerezPolitikasi') }}
                </router-link>
              </li>
              <li>
                <router-link to="/kvkk" v-scroll-to="'#topnav'" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> {{ $t('footer.ilkeler.rizaMetni') }}
                </router-link
                >
              </li>
            </ul>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
    <footer class="footer footer-bar">
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-left">
              <p class="mb-0">
                © {{ new Date().getFullYear() }} EPPSO Gayrimenkul Teknolojileri A.Ş.
              </p>
            </div>

          </div>
          <!--end col-->
          <div class="col-sm-6">
            <div class="text-sm-right">

              <p class="text-muted">
                Powered by
                <a href="https://www.gratas.com.tr" target="_blank">
                  <img src="../../public/images/GraTas-Logo-Web-WhiteScale.png" class="logo-img" height="24" alt="GraTas Solutions">
                </a>
              </p>

            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
  </div>
</template>
<style scoped>
.footer{
  text-align: center;
}
#cozumlerList > li{
  text-align: left;
  padding-left: 30%;
  white-space: nowrap;
}
#siteHaritasiList > li{
  text-align: left;
  padding-left: 30%;
}
.cozumlerList > li{
  text-align: left;
  padding-left: 30%;
  white-space: nowrap;
}
#ilkelerList > li{
  text-align: left;
  padding-left: 30%;
  white-space: nowrap;
}
.list-inline-item:hover{
  background-color: #88C5DA;
  transition-duration: 0.5s;
}
.list-inline-item{
  transition-duration: 0.5s;
}

@media (max-width: 767px) {
  /*.cozumlerList > li{
    text-align: left;
    padding-left: 35%;
    white-space: nowrap;
  }
  #ilkelerList > li{
    text-align: left;
    padding-left: 35%;
    white-space: nowrap;
  }
  #siteHaritasiList > li{
    text-align: left;
    padding-left: 35%;
  }*/
}
</style>
