import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue')
  },
  {
  path: '/',
  component: () => import('../layouts/MainLayout.vue'),
  children: [
    {
      path: '',
      name: 'home',
      component: () => import('../views/Home')
    },

    {
      path:'/Products_Mall',
      name: 'Products',
      component: () => import('../views/Products_Mall')
    },
    {
      path:'/Products_Retail',
      name: 'Products',
      component: () => import('../views/Products_Retail')
    },
    {
      path:'/Products_Office',
      name: 'Products',
      component: () => import('../views/Products_Office')
    },
    {
      path:'/Products_Industrial',
      name: 'Products',
      component: () => import('../views/Products_Industrial')
    },
    {
    path:'/Sectors_Avm',
    name: 'Sectors_Avm',
    component: () => import('../views/Sectors_Avm')
    },
    {
      path:'/Sectors_Retail',
      name: 'Sectors_Retail',
      component: () => import('../views/Sectors_Retail')
    },
    {
      path:'/Sectors_Office',
      name: 'Sectors_Office',
      component: () => import('../views/Sectors_Office')
    },
    {
      path:'/Sectors_Industrial',
      name: 'Sectors_Industrial',
      component: () => import('../views/Sectors_Industrial')
    },
    {
      path:'/references',
      name: 'references',
      component: () => import('../views/References')
    },
    {
      path:'/aboutUs',
      name: 'aboutUs',
      component: () => import('../views/AboutUs')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('../views/Contact')
    },
    {
      path: '/solutions',
      name: 'solutions',
      component: () => import('../views/Solutions')
    },
    {
      path: '/aydinlatma_metni',
      name: 'termsofuse',
      component: () => import('../views/AydinlatmaMetni')
    },
    {
      path: '/kvkk',
      name: 'privacy',
      component: () => import('../views/RizaMetni')
    },
    {
      path: '/cerez_politikasi',
      name: 'cerez',
      component: () => import('../views/CerezPolitikasi')
    },
    {
      path: '/uyelik_hizmet_sozlesmesi',
      name: 'uyelikHizmetsozlesmesi',
      component: () => import('../views/UyelikHizmetSozlesmesi')
    },
  ]
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
